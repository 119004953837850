<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Reportes</h4>
            <div class="small text-muted">Reportes de ventas</div>
          </b-col>                        
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="12">
          <b-card>
            <b-row >
              <b-col sm="10">            
                <b-row>            
                  <b-col sm="12" class="pb-1">
                    <b-row>         
                      <b-col md="3" class="p-1 pb-1">
                        <b-form-input type="date" size="md" v-model="filters.date_start" placeholder="Fecha Desde"></b-form-input>
                      </b-col>
                      <b-col md="3" class="p-1 pb-1">
                        <b-form-input type="date" size="md" v-model="filters.date_end" placeholder="Fecha Hasta"></b-form-input>
                      </b-col> 
                      <b-col sm="3" class="p-1 pb-1">
                        <v-select :options="arr.filters.status_imputation" v-model="filters.status_imputation" placeholder="Estado" :multiple="false" :select-on-tab="true"></v-select>
                      </b-col>                    
                    </b-row>
                  </b-col>         
                  <b-col sm="12">
                    <b-row>
                      <b-col sm="3" v-if="moduleSellersActive" class="p-1">
                        <v-select :options="arr.filters.sellers" v-model="filters.sellers" placeholder="Vendedores" :multiple="true" :select-on-tab="true"></v-select>
                      </b-col>                                    
                      <b-col sm="3" class="p-1">
                        <v-select :options="arr.filters.customers" v-model="filters.customers" placeholder="Clientes" :multiple="true" :select-on-tab="true"></v-select>
                      </b-col>  
                      <b-col sm="3" class="p-1">
                        <v-select :options="arr.filters.points_sales" v-model="filters.points_sales" placeholder="Punto de Venta" :multiple="false" :select-on-tab="true"></v-select>
                      </b-col> 
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col sm="2" class="p-1">
                <b-row>
                  <b-col sm="12">
                    <b-button variant="outline-dark" @click="loadByFilter()" size="sm" class="pull-right">
                      <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                      Filtrar
                    </b-button>
                  </b-col>                       
                </b-row>
              </b-col>
            </b-row>   
          </b-card>
        </b-col>
      </b-row>
      
      <b-tabs class="mb-3">         
        <b-tab title="Ventas">
          <div class="sales-flow-pivot">            
              <pivot :data="pivot.data" 
                    :fields="pivot.fields" 
                    :available-field-keys="pivot.availableFieldKeys"
                    :row-field-keys="pivot.rowFieldKeys" 
                    :col-field-keys="pivot.colFieldKeys" 
                    :reducer="pivot.reducer"
                    :default-show-settings="false"
                    :is-data-loading="pivot.isDataLoading"
                    no-data-warning-text="No se encontraron datos para esta consulta"
                    available-fields-label-text="Campos Disponibles"
                    rows-label-text="Filas"
                    cols-label-text="Columnas"
                    hide-settings-text="Cerrar Configuración"
                    show-settings-text="Configurar Informe"
                    select-all-text="Seleccionar Todo"
                    unselect-all-text="Quitar selección">

                <template slot="value" slot-scope="{ value }">
                  {{ value.toLocaleString() }}     
                </template>
                
                <template v-slot:payment="{ value }">
                  <span v-if="value == '0'">No Cobrado</span>
                  <span v-if="value == '1'">Cobrado</span>
                </template>                           

                <template slot="loading">
                  <div class="text-center">
                    Cargando...
                  </div>
                </template>      

                <template slot="foot-customer" slot-scope="{ value }">
                  {{value}}
                </template>
              </pivot>   
          </div>
        </b-tab>          
      </b-tabs>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>          
        </b-row>
      </b-card>
    
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Param from '@/config/parameters'
  import moment from 'moment'
  import Pivot from '@marketconnect/vue-pivot-table'

  export default {     
    components: {
      Pivot,
    },
    data: () => {
      return {      
        access: {
          module_id: Modules.VENTAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'reports',
          elements: {}
        },  
        pivot: {
          data: [],
          fields: [{
            key: 'staff',
            getter: item => (item.staff)?item.staff.name : 'Sin Asignar',
            label: 'Staff',
            valueFilter: true,
          }, {
            key: 'sellers',
            getter: item => (item.seller)?item.seller.name : 'Sin Asignar',
            label: 'Vendedor',
            valueFilter: true,
          }, {
            key: 'type_voucher',
            getter: item => (item.type_voucher)?item.type_voucher.name : 'Sin Asignar',
            label: 'Comprobante',
            valueFilter: true,
          }, {
            key: 'point_sale',
            getter: item => (item.point_sale)?item.point_sale.point_sale : 'Sin Asignar',
            label: 'Punto de Venta',
            valueFilter: true,
          }, {
            key: 'customer',
            getter: item => (item.customer)?item.customer.name : 'Sin Asignar',
            label: 'Cliente',
            valueFilter: true,
          }, {
            key: 'number',
            getter: item => (item.number)?item.number : 'Sin Asignar',
            label: 'Número',
            valueFilter: true,
          }, {
            key: 'date',
            getter: item => moment(item.date).format('DD/MM/YYYY'),
            label: 'Fecha',
            valueFilter: true
          }, {
            key: 'months',
            getter: item => moment(item.date).format('MMMM YYYY'),
            label: 'Mes',
            valueFilter: true
          }, {
            key: 'anio',
            getter: item => moment(item.date).format('YYYY'),
            label: 'Año',
            valueFilter: true
          }],   
          availableFieldKeys: ['date','anio','sellers','type_voucher','point_sale','number','customer'], 
          rowFieldKeys: ['staff'],
          colFieldKeys: ['months'],
          reducer: (sum, item) => sum + (item.type_voucher.type_balance == '+' ? parseFloat(item.amount_total) : parseFloat(item.amount_total) * -1),         
          isDataLoading: false, 
        },
        arr: {
          filters : {
            customers: [],
            sellers: [],
            points_sales: [],
            status_imputation: [
              {code: true, label: 'Cancelado'},
              {code: false, label: 'Pendiente'}
            ]
          },                       
        },
        filters: {
          date_start: '',
          date_end: '',
          customers: null,
          sellers: null,
          points_sales: null,
          status_imputation: null,
          paginate: false,
        },  
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.filterLoad()   
      
      setTimeout(()=>{
        this.loadByFilter()
      },200)
    },
    computed: {
      // MODULES
      moduleSellersActive() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules        
        modules.forEach(element => {
          if(element.id == Modules.VENDEDORES) {                             
            status = true                       
          }
        })        
        return status
      },
    },    
    methods: {
      filterLoad() {
        this.filterLoadCustomers()          
        this.filterLoadSellers()   
        this.filterLoadPointSale()   
        this.filters.date_start = moment().subtract(30, 'days').format('YYYY-MM-DD') 
        this.filters.date_end = moment().format('YYYY-MM-DD') 
      },
      filterLoadCustomers() {
        var result = serviceAPI.obtenerClientes()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.customers = []
          data.forEach(element => {         
            if(element.code) {
              this.arr.filters.customers.push({ code: element.id, label: element.code + ' | ' + element.name })            
            } else {
              this.arr.filters.customers.push({ code: element.id, label: element.name })            
            }                             
          });          
        })   
      },
      filterLoadSellers() {
        var result = serviceAPI.obtenerVendedores()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.sellers = []
          data.forEach(element => {                            
            this.arr.filters.sellers.push({ code: element.id, label: element.name })            
          });          
        })   
      },
      filterLoadPointSale() {
        var result = serviceAPI.obtenerPuntosVentas()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.points_sales = []
          data.forEach(element => {                            
            this.arr.filters.points_sales.push({ code: element.id, label: element.name })            
          });          
        })   
      },       
      loadByFilter() {
        var result = serviceAPI.filtrarComprobante(this.filters)

        result.then((response) => {
          var data = response.data
          
          this.pivot.isDataLoading = true
          this.pivot.data = data         
          this.pivot.isDataLoading = false            
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },
    }    
  }
</script>

<style>
  .sales-flow-pivot table thead tr th:empty {
    background: #fff;
    color: #fff;
    text-align: center;
    border: none;
  }  
  .sales-flow-pivot table thead tr {
    background: #2f353a;
    color: #fff;
    text-align: center;
  }  
  .sales-flow-pivot .table th, .table td {
      padding: 1px;
  }
  .sales-flow-pivot table tbody tr th {
    background: #2f353a;
    color: #fff;
    text-align: right;    
    vertical-align: middle !important;    
    padding-right: 5px !important;
  }    
  .sales-flow-pivot table tbody tr td {    
    color: #2f353a;
    background: #e2e2e2;
    text-align: right;    
    font-weight: 600 !important;
    vertical-align: middle !important;    
    padding-right: 5px !important;
  }      
</style>