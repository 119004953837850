<template>
  <b-row>
    <b-col>     
      <b-card>
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Ventas Rápida</h4>
            <div class="small text-muted">Interfaz para generar facturas mostrador</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card>
        <form-wizard  :title="wizard.title"
                      :subtitle="wizard.subtitle"
                      nextButtonText="Siguiente"
                      backButtonText="Atrás"
                      finishButtonText="Guardar"
                      color="#2f353a"
                      shape="tab"
                      @on-complete="save"
                      class="crud-sales-wizard"
                      ref="wizard">

          <tab-content title="General" icon="fa fa-edit" :before-change="saleValidCrudGeneral">          
            <b-row>      
              <b-col md="4">
                <FindObject render="search"
                            type="customers" 
                            @select-object="loadCustomers($event)" 
                            :valueID="crud.form.customers_id"
                            :where="conditionCustomersActive"
                            :key="crud.form.customers_id" />                 
              </b-col>                  
              <b-col md="4">
                <b-form-group label="Fecha">
                  <b-form-datepicker size="sm" v-model="crud.form.date" placeholder="Fecha" local="es" :readonly="true"></b-form-datepicker>                      
                </b-form-group>            
              </b-col>
              <b-col md="4">
                <b-form-group label="Fecha Vencimiento">
                  <b-form-datepicker size="sm" v-model="crud.form.date_expiration" placeholder="Fecha Vencimiento" local="es" :readonly="true"></b-form-datepicker>                      
                </b-form-group>            
              </b-col>
            </b-row>              
            <b-row> 
              <b-col md="4">
                <FindObject render="search"
                            type="erpPointsSales" 
                            @select-object="loadPointsSales($event)" 
                            :valueID="crud.form.points_sales_id"
                            :key="crud.form.points_sales_id" />                 
              </b-col>                              
              <b-col md="4">
                <FindObject render="search"
                            type="erpMethodsPayment" 
                            @select-object="loadMethodsPayment($event)" 
                            :valueID="crud.form.methods_payment_id"
                            :key="crud.form.methods_payment_id" />                 
              </b-col>                 
              <b-col md="4">
                <FindObject render="search"
                            type="priceList" 
                            @select-object="loadPriceList($event)" 
                            :valueID="crud.form.price_list_id"
                            :key="crud.form.price_list_id"
                            :where="conditionPriceListActive" />                 
              </b-col>                           
            </b-row>
            <b-row v-if="balanceCurrentAccount<0 && crud.form.selectTypeVoucher=='notacredito'">
              <b-col>
                <b-alert show variant="danger">
                  Se encontró saldo a favor del cliente: <b>({{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(balanceCurrentAccount)}})</b>
                </b-alert>
              </b-col>
            </b-row>            
          </tab-content>

          <tab-content title="Detalle" icon="fa fa-list" :before-change="saleValidCrudDetail">          
            <b-row>
              <b-col lg="12" md="12" sm="12">             
                  <Detail :customer_id="crud.form.customers_id" 
                          :price_list_id="crud.form.price_list_id"                   
                          @load-detail-products="setDetailItems($event, 'products')" 
                          @load-detail-products-compound="setDetailItems($event, 'productsCompound')" 
                          @load-detail-services="setDetailItems($event, 'services')" 
                          @load-detail-concepts="setDetailItems($event, 'concepts')" 
                          :detailProducts="crud.form.detailProducts"
                          :detailProductsCompound="crud.form.detailProductsCompound"
                          :detailServices="crud.form.detailServices"
                          :detailConcepts="crud.form.detailConcepts"
                          :hideConcepts="false"                           
                          :key="'detailInvoice_'+itemDetailForceUpdate"
                          :forceUpdate="itemDetailForceUpdate" 
                          :validItemDuplicatedProduct="false"
                          :barcodeAddAutomaticProduct="true" />                  
                  
                  <b-row class="mt-3 p-3" align-v="end">
                    <b-col md="2">
                      <b-row>
                        <b-col md="5" class="crud-sales-totales-items-header">
                          ITEMS
                        </b-col>
                        <b-col md="7" class="crud-sales-counts-items">
                          <b>{{this.getCantItemSale}}</b>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="6"></b-col>
                    <b-col md="4" v-if="getCurrencySale">
                      <b-row>
                        <b-col md="6" class="crud-sales-totales-items-header">
                          SUBTOTAL
                        </b-col>
                        <b-col md="6" class="crud-sales-totales-items">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencySale}).format(totals.subtotal)}}
                        </b-col>                    
                      </b-row>
                      
                      <b-row>
                        <b-col md="6" class="crud-sales-totales-items-header crud-sales-totales-discounts-items">
                          DESCUENTO (%)
                        </b-col>                     
                        <b-col md="6" class="crud-sales-totales-items">
                          <b-row>
                            <b-col class="p-0">
                              <b-form-input v-model="crud.form.percentage_discount" 
                                    type="number" 
                                    min="0" 
                                    max="100"
                                    step="1" 
                                    class="form-control"
                                    size="sm"
                                    @change="validDiscounts()"/>                                                  
                            </b-col>
                            <b-col class="crud-sales-totales-discounts-items">
                              {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencySale}).format(totals.discount)}}
                            </b-col>
                          </b-row>
                        </b-col>                    
                      </b-row> 
                      <b-row>
                        <b-col md="6" class="crud-sales-totales-items-header">
                          SUBTOTAL
                        </b-col>
                        <b-col md="6" class="crud-sales-totales-items">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencySale}).format(totals.subtotal_discount)}}
                        </b-col>                    
                      </b-row>                      
                      <b-row>
                        <b-col md="6" class="crud-sales-totales-items-header">
                          IVA
                        </b-col>
                        <b-col md="6" class="crud-sales-totales-items">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencySale}).format(totals.iva)}}
                        </b-col>                    
                      </b-row>            
                      <b-row>
                        <b-col md="6" class="crud-sales-totales-items-header">
                          TOTAL
                        </b-col>
                        <b-col md="6" class="crud-sales-totales-items-amount">
                          <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencySale}).format(totals.total)}}</b>
                        </b-col>                    
                      </b-row>                                                                  
                    </b-col>
                  </b-row>
                  
              </b-col>        
            </b-row>
          </tab-content>

          <tab-content title="Asiento Contable" icon="fa fa-balance-scale" :before-change="salesValidCrudAccounting" v-if="showContabilidad"> 
            <div v-if="contabilidad.render" :key="'accounting_' + itemAccountingForceUpdate">
              <AccountingEntries  :typeDebe="contabilidad.typeDebe"
                                  :typeHaber="contabilidad.typeHaber"
                                  :defaultDebe="contabilidad.defaultDebe"
                                  :defaultHaber="contabilidad.defaultHaber"
                                  :reference="contabilidad.reference"
                                  :amountTotal="contabilidad.amountTotal"
                                  @getAsiento="getAsiento($event)" />         
            </div>
          </tab-content>

        </form-wizard>
      </b-card>

      <b-row>
        <b-col>  
          <b-card>
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>                     
              <b-col>
                <b-button type="button" variant="warning" size="sm" @click="cancel" class="pull-right">
                  <i class="fa fa-repeat"></i>
                  Reiniciar
                </b-button>                        
              </b-col>                     
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!--
      ###########################
      ######### PRINTER #########
      ###########################
      -->
      <Printer v-if="crud.print.id" 
              :reference="crud.print.reference" 
              :registerID="crud.print.id"
              :key="crud.print.key"/>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'  
  import Printer from '@/components/inc/printer/printer'
  import Detail from '@/components/inc/cpbtDetail/detail'
  import moment from 'moment'
  import AccountingEntries from '@/components/inc/accounting/entries'  
  import VoucherRelation from './inc/detailInvoice'
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'

  export default {
    components: {
      FindObject,
      Printer,
      Detail,
      FormWizard,
      TabContent,     
      AccountingEntries, 
      VoucherRelation,
    },
    data: () => {
      return {      
        access: {
          module_id: Modules.VENTAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'salesQuick',
        },              
        crud: {
          form: {
            id: 0,
            date: '',
            date_expiration: '',              
            types_vouchers_id: 0,
            types_vouchers: null,
            letter: '',
            number: 0,
            methods_payment_id: 0,
            methods_payment: null,
            price_list_id: 0,
            price_list: null,            
            points_sales_id: 0,
            points_sales: null,              
            observations: '',            
            staff_id: 0,
            sellers_id: 0,
            customers_id: 0,
            customers: null,   
            percentage_discount: 0,                                 
            detailProducts: [],
            detailProductsCompound: [],
            detailServices: [],
            detailConcepts: [],
            invoiceRelated: [],
            selectTypeVoucher: 'factura',
            selectDestination: 'default',
            accountingEntry: null,
            concepts: null,
            concepts_id: 0,
            remits: null,
            remits_id: 0,
            orders: null,
            orders_id: 0,            
          },          
          formPending: [],  
          print: {
            reference: '',
            id: 0,
            key: 0,
          }               
        },
        arr: {
          ivaCondition: [],
        },
        wizard: {
          title: '',
        },
        totals: {
          subtotal: 0,
          discount: 0,
          iva: 0,
          total: 0,
          arrIva: [],            
        },           
        itemSelected: null,         
        itemDetailForceUpdate: 0,
        itemAccountingForceUpdate: 0,    
        contabilidad: {
          render: false,
          typeDebe:"",                                 
          typeHaber:"",
          defaultDebe:[],
          defaultHaber:[],
          reference:'',
          amountTotal:0
        },
        aux: {
          detailProducts: [],
          detailProductsCompound: [],
          detailServices: [],
          detailConcepts: [],          
        },
        calc: {
          discounts: 0,
        },       
        balanceCurrentAccount: 0,                    
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)      
      /* Fin configuracion */
    },     
    mounted() {   
      this.load()
      this.getIvaCondition()
    },    
    computed: {
      // MODULES
      moduleSellersActive() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules        
        modules.forEach(element => {
          if(element.id == Modules.VENDEDORES) {                             
            status = true                       
          }
        })        
        return status
      },

      // MODULES
      showContabilidad() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules        
        modules.forEach(element => {
          if(element.id == Modules.CONTABILIDAD) {                             
            status = true                       
          }
        })        
        return status
      },

      // OTHERS
      subtotalItemSelected() {
        if(this.itemSelected) {
          return parseFloat(this.itemSelected.amount_total)
        } else {
          return 0
        }
      },

      // CONDITIONS SELECT
      conditionCustomersActive(){
        return [{field: 'active', condition: true}];
      },      
      conditionPriceListActive(){
        return [{field: 'active', condition: true}];
      },      

      // CANT / CURRENCY      
      getCantItemSale() {
        var cant1 = this.crud.form.detailProducts.length
        var cant2 = this.crud.form.detailProductsCompound.length
        var cant3 = this.crud.form.detailServices.length
        var cant4 = this.crud.form.detailConcepts.length

        return parseFloat(cant1 + cant2 + cant3 + cant4)
      },
      getCurrencySale() {        
        var currency = 'ARS'
        if(this.crud.form.price_list) {                    
          if(this.crud.form.price_list.currency) {                   
            currency = this.crud.form.price_list.currency.code            
          }
        }         
        return currency                      
      },
      
      // VOUCHER
      getRefVoucher() {        
        if(this.crud.form.types_vouchers && this.crud.form.points_sales && this.crud.form.number) {
          return this.crud.form.types_vouchers.name + ' '  + this.crud.form.points_sales.point_sale.toString().padStart(4,'0') + '-' + this.crud.form.number.toString().padStart(8,'0')  
        } else {
          return ''
        }      
      },

      // CONFIGURACION      
      getCurrency() {
        return 'ARS'
      },
    },
    watch: {        
      'crud.form.customers_id': function (newQuestion, oldQuestion) {         
        this.searchVoucherByCustomer()
      },
      'crud.form.points_sales_id': function (newQuestion, oldQuestion) {                
        this.searchVoucherByCustomer()
      },
      'crud.form.price_list_id': function (newQuestion, oldQuestion) {                
        this.detailForceUpdate();
      },            
      'crud.form.detailProducts': function (newQuestion, oldQuestion) {                                
        this.searchTotalsTemporary()
      },
      'crud.form.detailProductsCompound': function (newQuestion, oldQuestion) {       
        this.searchTotalsTemporary()
      },        
      'crud.form.detailServices': function (newQuestion, oldQuestion) {        
        this.searchTotalsTemporary()
      },
      'crud.form.detailConcepts': function (newQuestion, oldQuestion) {
        this.searchTotalsTemporary()
      }            
    },    
    methods: {   
      // SELECT
      loadCustomers (object) {        
        if(object){
          this.crud.form.customers = object
          this.crud.form.customers_id = object.id   
          
          if(object.data_fiscal) {
            if(object.data_fiscal.points_sales_id) {
              if(!this.crud.form.points_sales_id) {
                this.crud.form.points_sales = object.data_fiscal.points_sales
                this.crud.form.points_sales_id = object.data_fiscal.points_sales_id
              }
            }

            if(object.data_fiscal.methods_payment_id) {
              if(!this.crud.form.methods_payment_id) {
                this.crud.form.methods_payment = object.data_fiscal.methods_payment
                this.crud.form.methods_payment_id = object.data_fiscal.methods_payment_id                            

                let days = parseInt(object.data_fiscal.methods_payment.term)
                this.crud.form.date_expiration = moment(this.crud.form.date).add(days,'d').format('YYYY-MM-DD')
              }
            }
          }
          
          if(!this.crud.form.price_list_id) {
            if(object.price_list) {
              if(object.price_list.list) {              
                this.crud.form.price_list = object.price_list.list
                this.crud.form.price_list_id = object.price_list.list.id
              }
            }          
          }    
          
          this.getBalanceCurrentAccount()
        } else {
          this.crud.form.customers = null
          this.crud.form.customers_id = 0

          this.balanceCurrentAccount = 0
        }
      }, 
      loadPointsSales (object) {                
        if(object){
          this.crud.form.points_sales = object
          this.crud.form.points_sales_id = object.id       
          
          this.getBalanceCurrentAccount()
        } else {
          this.crud.form.points_sales = null
          this.crud.form.points_sales_id = 0

          this.getBalanceCurrentAccount()
        }
      },      
      loadMethodsPayment (object) {
        if(object){
          this.crud.form.methods_payment = object
          this.crud.form.methods_payment_id = object.id             

          let days = parseInt(object.term)
          this.crud.form.date_expiration = moment(this.crud.form.date).add(days,'d').format('YYYY-MM-DD')                          
        } else {
          this.crud.form.methods_payment = null
          this.crud.form.methods_payment_id = 0
        }
      },
      loadPriceList (object) {
        if(object){
          this.crud.form.price_list = object
          this.crud.form.price_list_id = object.id
        } else {
          this.crud.form.price_list = null
          this.crud.form.price_list_id = 0
        }
      },        
      
      // AMOUNT CURRENT ACCOUNT
      getBalanceCurrentAccount() {
        var result = serviceAPI.filtrarCurrentsAccountsBalance({
          customers_id: this.crud.form.customers_id,
          points_sales_id: this.crud.form.points_sales_id, 
        })

        result.then((response) => {
          var data = response.data
          this.balanceCurrentAccount = data
        })
      },

      // ABM MAIN
      load(type='start') {        
        this.crud.form.id = 0           
        this.crud.form.observations = ''
        this.crud.form.staff_id = Helper.getStaffId()
        this.crud.form.sellers_id = 0
        this.crud.form.percentage_discount = 0                                 
        this.crud.form.detailProducts = []
        this.crud.form.detailProductsCompound = []
        this.crud.form.detailServices = []
        this.crud.form.detailConcepts = []
        this.balanceCurrentAccount = 0

        if(type == 'start') {
          this.crud.form.customers_id = 0
          this.crud.form.customers = null   
          this.crud.form.points_sales_id = 0
          this.crud.form.points_sales = null  
          this.crud.form.methods_payment_id = 0
          this.crud.form.methods_payment = null      
          this.crud.form.price_list_id = 0
          this.crud.form.price_list = null         
          this.crud.form.date = moment().format('YYYY-MM-DD')
          this.crud.form.date_expiration = moment().add(10,'d').format('YYYY-MM-DD')          
        } 

        if(type == 'restart') {
          this.searchVoucherByCustomer()

          this.detailForceUpdate();
          this.forceUpdate();
        }
				
        this.wizard.title = "Nueva Factura"
        this.wizard.subtitle = ""
				
        this.crud.form.selectTypeVoucher = 'factura'
        this.crud.form.selectDestination = 'default'       
      },                     
      save() {
        this.$bvModal.msgBoxConfirm('¿Desea continuar con la generación del comprobante?', {
          title: 'Generar Comprobante',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GENERAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {          
            let loader = this.$loading.show();
            
            var result = serviceAPI.agregarComprobante(this.crud.form);
            
            result.then((response) => {
              var data = response.data
              this.sendPrinterSale(data)

              loader.hide()        
              this.load('restart')      

              if(this.$refs.wizard) {            
                this.$refs.wizard.changeTab(2,1)
              }          
                            
              this.$awn.success("Comprobante generado con éxito");              
            })
            .catch(error => {              
              loader.hide()
              this.load('restart')      

              if(this.$refs.wizard) {            
                this.$refs.wizard.changeTab(2,1)
              } 

              this.$awn.alert(Error.showError(error));
            })
          } else {            
            if(this.$refs.wizard) {            
              this.$refs.wizard.changeTab(2,1)
            }             
          }
        })
        .catch(error => {
          this.load('restart')      

          if(this.$refs.wizard) {            
            this.$refs.wizard.changeTab(2,1)
          }           

          this.$awn.alert(Error.showError(error));
        })              
      },          
      cancel() {
        this.$bvModal.msgBoxConfirm('¿Desea reiniciar el comprobante?', {
          title: 'Reiniciar Comprobante',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'warning',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'warning',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            this.load('start')      

            if(this.$refs.wizard) {            
              this.$refs.wizard.changeTab(0,0)
            } 
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })   
      },              
      setDetailItems(event, type) { 
        if(type=="products") {
          this.crud.form.detailProducts = event                
        } 
        if(type=="productsCompound") {          
          this.crud.form.detailProductsCompound = event                
        }               
        if(type=="services") {
          this.crud.form.detailServices = event                
        } 
        if(type=="concepts") {
          this.crud.form.detailConcepts = event                
        }                 
      },
      detailForceUpdate() {
        this.itemDetailForceUpdate = this.itemDetailForceUpdate + 1
      },

      // VALID
      saleValidCrudGeneral() {        
        if(this.crud.form.customers_id && this.crud.form.points_sales_id  && this.crud.form.methods_payment_id) {
          return true;
        } else {
          if(!this.crud.form.customers_id) {
            this.$awn.alert("No se cargó el cliente");
            return false;
          }
          if(!this.crud.form.points_sales_id) {
            this.$awn.alert("No se cargó el punto de venta");
            return false;
          }                        
          if(!this.crud.form.methods_payment_id) {
            this.$awn.alert("No se cargó el método de pago");
            return false;
          }                         
        }        
      },
      saleValidCrudDetail() {
        if( this.crud.form.detailProducts.length || 
            this.crud.form.detailProductsCompound.length || 
            this.crud.form.detailServices.length || 
            this.crud.form.detailConcepts.length) {
          this.getContabilidad()          
          return true;
        } else {
          this.$awn.alert("No se cargó ningún item");
          return false;
        }
      },
      salesValidCrudAccounting() {        
        if(this.crud.form.accountingEntry) {
          return true
        } else {
          this.$awn.alert("Revisar el Asiento Contable");
          return false
        }
      },    

      // SELECT VOUCHER 
      searchVoucherByCustomer() {
        if(!this.crud.form.id) {
          if(this.crud.form.customers_id && this.crud.form.points_sales_id ) {
            var result = serviceAPI.obtenerVoucherByCustomer({
              'customers_id': this.crud.form.customers_id,
							'reference': this.crud.form.selectTypeVoucher,
            });
            result.then((response) => {    
							this.crud.form.types_vouchers_id = response.data[0].id							
							this.searchVoucher()                          
            })
						.catch(error => {
							this.$awn.alert(Error.showError(error));
						})   						           
          }
        } else {                                      
          if( this.crud.form.types_vouchers ) {    
            // simple                      
            this.wizard.title = this.crud.form.types_vouchers.name
            this.wizard.title = this.wizard.title + ' ' + this.crud.form.points_sales.point_sale.toString().padStart(4,'0')
            this.wizard.title = this.wizard.title + '-' + this.crud.form.number.toString().padStart(8,'0')            
          }                   
        }
      },			
      searchVoucher() {
        if(!this.crud.form.id) {
          if(this.crud.form.customers_id && this.crud.form.points_sales_id ) {
            var result = serviceAPI.obtenerVoucherById({
              'points_sales_id': this.crud.form.points_sales_id,
              'types_vouchers_id' : this.crud.form.types_vouchers_id,
            });
            result.then((response) => {                      
              this.crud.form.types_vouchers = response.data.type_voucher
              this.crud.form.letter = response.data.type_voucher.letter                    
              this.crud.form.number = response.data.number
              
              if( this.crud.form.types_vouchers ) {              
                // simple
                this.wizard.title = this.crud.form.types_vouchers.name
                this.wizard.title = this.wizard.title + ' ' + this.crud.form.points_sales.point_sale.toString().padStart(4,'0')
                this.wizard.title = this.wizard.title + '-' + this.crud.form.number.toString().padStart(8,'0')              
              }
                          
            })           
          }
        } else {                                      
          if( this.crud.form.types_vouchers ) {                          
            this.wizard.title = this.crud.form.types_vouchers.name          
            this.wizard.title = this.wizard.title + ' ' + this.crud.form.points_sales.point_sale.toString().padStart(4,'0')
            this.wizard.title = this.wizard.title + '-' + this.crud.form.number.toString().padStart(8,'0')
          }                   
        }
      },
      searchTotalsTemporary() {
        if(this.crud.form.detailProducts || this.crud.form.detailProductsCompound || this.crud.form.detailServices || this.crud.form.detailConcepts) {
          var result = serviceAPI.obtenerVoucherTotalsTemporary({
            'header': this.crud.form,
            'detailsProducts': this.crud.form.detailProducts,
            'detailsProductsCompound': this.crud.form.detailProductsCompound,   
            'detailsServices': this.crud.form.detailServices,
            'detailsConcepts': this.crud.form.detailConcepts,
          });
          result.then((response) => {             
            var data = response.data   
            
            this.aux.detailProducts = data.detailProducts
            this.aux.detailProductsCompound = data.detailProductsCompound
            this.aux.detailServices = data.detailServices
            this.aux.detailConcepts = data.detailConcepts

            this.totals.subtotal = data.amount_net
            this.totals.discount = data.amount_discount
            this.totals.subtotal_discount = data.amount_subtotal
            this.totals.iva = data.amount_iva
            this.totals.total = data.amount_total
            this.totals.arrIva = data.arrIva
          })           
        }                   
      },
      validDiscounts() {
        if(this.crud.form.percentage_discount=='') {          
          this.crud.form.percentage_discount = 0
        } else {          
          if( parseFloat(this.crud.form.percentage_discount) < 0 || 
              parseFloat(this.crud.form.percentage_discount) > 100 ) {            
            this.crud.form.percentage_discount = 0
          } 
        }       
        this.searchTotalsTemporary()
      },      
    
      // PRINT
      sendPrinterSale(item) { 
        this.crud.print.key=Math.random()
        this.crud.print.id=item.id
        this.crud.print.reference = item.reportDefault        
      },      

      /****************
       * CONTABILIDAD *
       * **************/      
      getContabilidad() {          
        this.contabilidad.render = false
        this.contabilidad.typeDebe = ""
        this.contabilidad.typeHaber = "",
        this.contabilidad.defaultDebe = []
        this.contabilidad.defaultHaber = []
        this.contabilidad.reference = this.getRefVoucher
        this.contabilidad.amountTotal = parseFloat(this.totals.total)
        
        // ASIENTO POR DEFECTO 
        if( this.crud.form.selectTypeVoucher == 'factura' || 
            this.crud.form.selectTypeVoucher == 'notadebito') {              
          this.contabilidad.defaultDebe = this.getContabilidadAsientoVenta('debe')
          this.contabilidad.defaultHaber = this.getContabilidadAsientoVenta('haber')
        } 
        // END
        
        this.contabilidad.render = true
        this.forceUpdate()

        setTimeout(()=>{
          if(this.crud.form.accountingEntry) {
            this.save()
          } 
        },100)       
      },

      getAsiento(object) {
        this.crud.form.accountingEntry = object
      },
      forceUpdate() {
        this.itemAccountingForceUpdate = this.itemAccountingForceUpdate + 1
      },

      getIvaCondition() {
        var result = serviceAPI.obtenerCondicionesIva()        
        result.then((response) => {
          var data = response.data    
          this.arr.ivaCondition = data          
        })   
      },  

      getContabilidadAsientoVenta(type) {                     
        if(type == 'debe') {        
          
          var arrDebe = []

          var totalConcepts = 0
          if(this.aux.detailConcepts) {
            this.aux.detailConcepts.forEach(element => {                   
              totalConcepts = totalConcepts + parseFloat(element.price) + parseFloat(element.iva)

              arrDebe.push(
                {
                  account: element.concepts.accounting_account_default,
                  amount: parseFloat(element.price) + parseFloat(element.iva),
                  disabled: true,
                }
              )
            });
          }

          if( parseFloat(this.totals.total) > totalConcepts ) {
            arrDebe.push(
              {
                account: this.crud.form.customers.accounting_account_default,
                amount: parseFloat(this.totals.total) - totalConcepts,
                disabled: true,
              }
            )
          }

          return arrDebe
        }        

        if(type == 'haber') {        
          var arrHaber = [
            {
              account: this.crud.form.customers.accounting_account_secondary,
              amount: parseFloat(this.totals.total) - parseFloat(this.totals.iva),
              disabled: true,
            }
          ]

          if(this.totals.arrIva) {
            Object.entries(this.totals.arrIva).forEach(([index1, element1]) => {              
              this.arr.ivaCondition.forEach(element2 => {              
                if(index1 == element2.id) {
                  arrHaber.push({
                    account: element2.accounting_account_default,
                    amount: parseFloat(element1),
                    disabled: true,                  
                  })
                }
              });
            })
            
          }     
          
          return arrHaber
        }        
      },          
    }
  }
</script>
<style>
  .crud-sales-title {
    font-size: 15px;
    font-weight: 600;
  }
  .crud-sales-totales-items-header {
    background: var(--dark);
    color: #fff;
    font-size: 12px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;    
    text-align: right;
    font-weight: 700;
  }
  .crud-sales-counts-items {
    font-size: 12px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: solid;
    border-width: 1px;    
  }  
  .crud-sales-totales-items {
    font-size: 12px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: dotted;
    border-width: 1px;    
  }  
  .crud-sales-totales-items-amount {
    font-size: 15px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: dotted;    
    border-width: 1px;    
  }    
  .crud-sales-wizard .wizard-header {
    padding: 0px;
  }  
  .crud-sales-totales-discounts-items {
    padding-top: 5px;
  }  
  #view_list_sales .card-body {
    padding: 5px;
  } 
  .crud-sales-wizard .wizard-title {
    text-align: left;
  }
  .crud-sales-wizard .category {
    text-align: left;
  }  
</style>