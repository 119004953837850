<template>
  <div :key="'detailInvoiceProductsCompoundTable_' + forceUpdate">    
    <b-row :class="[{'block-product-add':crud.form.detail_register.editing}]">
      <b-col :lg="7 + widthShowDiscount" :md="7 + widthShowDiscount">
        <b-row>
          <b-col lg="6" md="6">
            <FindObject :key="crud.form.detail_register.forceRenderSelectProduct"
                        render="search"
                        type="productsCompound" 
                        @select-object="loadProductsCompound($event)" 
                        :valueID="crud.form.detail_register.productCompound_id"
                        :where="conditionProductsCompoundActive"/> 
          </b-col>              
        </b-row>
      </b-col>
      <b-col :lg="5 - widthShowDiscount" :md="5 - widthShowDiscount">
        <b-row>
          
          <b-col :lg="3 + widthShowDiscount" :md="3 + widthShowDiscount">
            <b-form-group label="Cantidad">                                                                 
              <b-form-input v-model="crud.form.detail_register.quantity" 
                            type="number" 
                            min="0" 
                            step="1" 
                            class="form-control"
                            size="sm"/>              
            </b-form-group>
          </b-col>                   

          <b-col :lg="4 + widthShowDiscount" :md="4 + widthShowDiscount" class="pl-0 pr-0">

            <div  v-if="crud.form.detail_register.unit_measurement">                  
              <b-form-group label="Importe" v-if="crud.form.detail_register.currency"> 
                <b-input-group :prepend="crud.form.detail_register.currency.symbol" size="sm" class="crud-detail-product-prepend-amount">
                  <b-form-input v-model="crud.form.detail_register.amount" 
                                type="number" 
                                :disabled="!editPrice"
                                min="0" 
                                step="0.01" 
                                class="form-control"/>                      

                  <template #append>
                    <b-button v-if="crud.form.detail_register.unit_measurement" size="sm" variant="secondary" class="curd-detail-product-select-unit-measurement" :text="crud.form.detail_register.unit_measurement.reference">
                      {{crud.form.detail_register.unit_measurement.reference}} 
                    </b-button>
                  </template>                            
                </b-input-group>
              </b-form-group>
              <b-form-group label="Importe" v-else size="sm"> 
                <b-input-group prepend="$" size="sm" class="crud-detail-product-prepend-amount">
                  <b-form-input v-model="crud.form.detail_register.amount" 
                                type="number" 
                                :disabled="!editPrice"
                                min="0" 
                                step="0.01"                        
                                class="form-control"/>   

                    <template #append>
                      <b-button v-if="crud.form.detail_register.unit_measurement" size="sm" variant="secondary" class="curd-detail-product-select-unit-measurement" :text="crud.form.detail_register.unit_measurement.reference">
                        {{crud.form.detail_register.unit_measurement.reference}} 
                      </b-button>
                    </template>                                               
                </b-input-group>
              </b-form-group>  
            </div>
            <div v-else>
              <b-form-group label="Importe" v-if="crud.form.detail_register.currency"> 
                <b-input-group :prepend="crud.form.detail_register.currency.symbol" size="sm" class="crud-detail-product-prepend-amount">
                  <b-form-input v-model="crud.form.detail_register.amount" 
                                type="number" 
                                :disabled="!editPrice"
                                min="0" 
                                step="0.01" 
                                class="form-control"/>                      
                </b-input-group>
              </b-form-group>
              <b-form-group label="Importe" v-else size="sm"> 
                <b-input-group prepend="$" size="sm" class="crud-detail-product-prepend-amount">
                  <b-form-input v-model="crud.form.detail_register.amount" 
                                type="number" 
                                :disabled="!editPrice"
                                min="0" 
                                step="0.01"                        
                                class="form-control"/>                      
                </b-input-group>
              </b-form-group> 
            </div>                         
          </b-col>

          <b-col lg="3" md="3" v-if="showDiscount">
            <b-form-group label="Descuento">                         
              <b-input-group append="%" size="sm" class="crud-detail-product-append-discount">
                <b-form-input v-model="crud.form.detail_register.discount" 
                      type="number" 
                      min="0" 
                      max="100"
                      step="1" 
                      class="form-control"
                      size="sm"/>                      
              </b-input-group>
            </b-form-group>              
          </b-col>        

          <b-col lg="1" md="1" class="crud-new-orders-button-add">
            <b-button @click="addItem()" 
                      v-b-tooltip.hover 
                      :variant="crud.form.detail_register.button.variant"
                      :title="crud.form.detail_register.button.title"
                      size="sm">
              <i :class="crud.form.detail_register.button.icon"></i>
            </b-button>                                                    
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="12" v-if="crud.form.detail_register.editing" class="block-product-add-div">
        <b-icon icon="circle-fill" animation="throb" font-scale="2"></b-icon>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col md="12">                  
        <b-table  class="mb-0 crud-order-table-detail"
                  responsive="sm"
                  head-variant="dark"
                  :hover="true"
                  :small="true"
                  :fixed="false"
                  :items="crud.form.detail"
                  :fields="tableDetailNewOrder">
          
          <template v-slot:cell(code)="data">              
            {{data.item.productCompound.code}}
          </template>  

          <template v-slot:cell(product)="data">  
            {{data.item.productCompound.name}}              
          </template>   

          <template v-slot:cell(list)="data">     
            <span v-if="data.item.price_list">
              {{data.item.price_list.reference}}
            </span>
          </template>   

          <template v-slot:cell(quantity)="data">                            
            {{ parseFloat(data.item.quantity).toFixed(2) }} 
            <span v-if="data.item.unit_measurement">{{data.item.unit_measurement.reference}}</span>
          </template>   

          <template v-slot:cell(amount)="data">  
            {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.currency.code}).format(data.item.amount)}}                          
          </template>   

          <template v-slot:cell(subtotal)="data">  
            {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.currency.code}).format(getRegSubTotals(data.item))}}                          
          </template>   

          <template v-slot:cell(discount)="data">  
            ({{data.item.discount}}%)                          
            {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.currency.code}).format(getRegDiscount(data.item))}}                          
          </template>   
          
          <template v-slot:cell(total)="data">  
            {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.currency.code}).format(getRegTotals(data.item))}}                          
          </template>   

          <template v-slot:cell(f_action)="data">  
            <b-dropdown right 
                        text="Acción" 
                        size="sm" 
                        variant="outline-dark"  
                        :disabled="crud.form.detail_register.button.editing">                      
              <b-dropdown-item @click="editItem(data.item, data.index)">
                <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
              </b-dropdown-item>
              <b-dropdown-item @click="deleteItem(data.index)" style="color:red;">
                <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
              </b-dropdown-item>     
            </b-dropdown>                                
          </template>                    
        </b-table>                  
      </b-col>
    </b-row> 

    <!-- ########################### -->
    <!-- #####     MODALES     ##### -->
    <!-- ########################### -->

    <!-- CRUD MULTIPLE LIST PRICE -->
    <b-modal v-model="modal.multipleListPrice.active"
            header-bg-variant="dark"
            header-text-variant="white"            
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close>
      <div slot="modal-header">
        {{this.modal.multipleListPrice.title}}
      </div>

      <b-row>
        <b-col md="12" class="mb-2">          
          <div class="detail-product-multiple-list-price-title">
            <span class="detail-product-multiple-list-price-code">
              {{this.modal.multipleListPrice.code}}   
            </span><br>            
            {{this.modal.multipleListPrice.product}}            
          </div>              
        </b-col>        
        <b-col lg="12">          
          <b-table 
            class="mb-0"
            responsive="sm"
            head-variant="dark"
            :hover="true"
            :small="true"
            :fixed="false"
            :items="tableMultipleListPrice.items"
            :fields="tableMultipleListPrice.fields">
            
            <template v-slot:cell(list)="data">  
              <div v-if="data.item.list">
                {{data.item.list.reference}}
              </div>
            </template>  

            <template v-slot:cell(price)="data">                
              <div v-if="data.item && data.item.list && data.item.list.currency">
                {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(data.item.price_simple)}}
              </div>
            </template>

            <template v-slot:cell(action)="data">  
              <b-button variant="outline-dark" size="sm" @click="selectMultipleListPrice(data.item)">
                Seleccionar
                <b-icon icon="chevron-double-right"></b-icon>
              </b-button>
            </template>                          
          </b-table>
        </b-col>
      </b-row>

      <div slot="modal-footer">
        <b-button variant="outline-secondary" size="sm" class="mr-1" @click="cancelMultipleListPrice()">Cancelar</b-button>        
      </div>
    </b-modal>   

  </div>       
</template>

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'    
  import Param from '@/config/parameters'
  import moment from 'moment'

  export default {
    props: {
      customer_id:{
        type: Number,
        required: true,
      },
      price_list_id:{
        type: Number,
        default: 0,
      },            
      editPrice: {
        type: Boolean,
        default: false,
      },
      validStock: {
        type: Boolean,
        default: false,
      },
      detailEdit: {
        type: [Array, Object],
        default: null,
      },
      showDiscount: {
        type: Boolean,
        default: true,        
      },
      forceUpdate: {
        type: Number,
        default: 0,
      },      
    },
    components: {
      FindObject,
    },
    data: () => {
      return {  
        tableDetailNewOrder : [],    
        tableMultipleListPrice : {
          items: [],
          fields: [],
        },               
        crud: {
          form: {
            detail_register: {
              quantity: 0,
              quantityOld: 0,
              amount: 0,
              discount: 0,
              currency: null,              
              productCompound_id: 0,
              productCompound: null,     
              forceRenderSelectProduct: 0,
              unit_measurement: null,
              unit_measurement_id: 0,
              amount_initial: 0,
              price_list_id: 0,
              price_list: null,              
              button: {
                icon: 'fa fa-plus',
                title: 'Agreagr Item',
                variant: 'success',
                editing: false
              },
              editing: false,
            },            
            detail: [],   
            editOrder: false,    
          },               
        },
        editing: {
          status: false,
          price: 0,
          unit_measurement: null,
        },     
        modal: {
          multipleListPrice: {
            active: false,
            title: '', 
            code: '',
            product: '',
          },       
        },          
      }
    },
    watch: {      
      'crud.form.detail_register.productCompound': function (newQuestion, oldQuestion) {          
        if(newQuestion) {          
          if(!(this.editPrice && this.editing.status)) {          
            if(this.customer_id) {                    
              var result = serviceAPI.obtenerPrecioProductoCompuesto({
                products_compound: newQuestion,
                customer_id: this.customer_id,
                price_list_id: this.price_list_id
              })        
              result.then((response) => {
                var data = response.data   

                this.tableMultipleListPrice.items = data
                
                if (Array.isArray(data)) {              
                  this.modal.multipleListPrice.title = "Selección de Lista de Precios"
                  this.modal.multipleListPrice.code = newQuestion.code
                  this.modal.multipleListPrice.product = newQuestion.name
                  this.modal.multipleListPrice.active = true                                    
                } else {                  
                  this.crud.form.detail_register.currency= data.list.currency 
                  this.crud.form.detail_register.amount = data.price_simple   
                  this.crud.form.detail_register.amount_initial = data.price_simple      
                  
                  if(data.list) {
                    this.crud.form.detail_register.price_list_id = data.list.id
                    this.crud.form.detail_register.price_list = data.list                  
                  }                  
                }                         
              })             
            }                 

            if(newQuestion.unit_measurement) {
              this.crud.form.detail_register.unit_measurement = newQuestion.unit_measurement
              this.crud.form.detail_register.unit_measurement_id = newQuestion.unit_measurement.id
            }
          }                            

          this.editing.status = false
          this.editing.price = 0
          this.editing.unit_measurement = null      
        }                
      },
    },  
    computed: {    
      // CONDITIONS SELECT  
      conditionProductsCompoundActive() {
        return [{field: 'active', condition: true}];
      },    

      // CONFIGURATION SHOW DISCOUNT    
      widthShowDiscount () {
        if(this.showDiscount) {
          return 0
        } else {
          return 1
        }
      },

      // MODULES ACTIVES
      moduleDepositActive() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules
        modules.forEach(element => {
          if(element.id == Modules.DEPOSITOS) {
            status = true
          }
        })
        return status
      },            
    },
    mounted() {
      this.configFieldsTable()
      this.loadItems()
    },
    methods: {
      configFieldsTable() {
        // TABLE DETAILS
        this.tableDetailNewOrder.push({key: 'code', label: 'Código', class:"align-middle"})              
        this.tableDetailNewOrder.push({key: 'product', label: 'Producto', class:"align-middle"})         
        this.tableDetailNewOrder.push({key: 'list', label: 'Lista', class:"align-middle text-center"})             
        this.tableDetailNewOrder.push({key: 'quantity', label: 'Cantidad', class:"align-middle text-center"})
        this.tableDetailNewOrder.push({key: 'amount', label: 'Importe', class:"align-middle text-right"})        
        
        if(this.showDiscount) {
          this.tableDetailNewOrder.push({key: 'subtotal', label: 'Subtotal', class:"align-middle text-right"})
          this.tableDetailNewOrder.push({key: 'discount', label: 'Descuento', class:"align-middle text-right"})
        }        

        this.tableDetailNewOrder.push({key: 'total', label: 'Total', class:"align-middle text-right"})
        this.tableDetailNewOrder.push({key: 'f_action', label:'Acción', class:"align-middle text-right"})

        // TABLE MULTIPLE PRICE
        this.tableMultipleListPrice.fields.push({key: 'list', label: 'Lista', class:"align-middle"})
        this.tableMultipleListPrice.fields.push({key: 'price', label: 'Precio', class:"align-middle text-right"})
        this.tableMultipleListPrice.fields.push({key: 'action', label: '', class:"align-middle text-right"})        
      },
      loadItems() {              
        if(this.detailEdit) {
          this.detailEdit.forEach(element => {         
            var arrItem = { 
              productCompound: element.productCompound, 
              quantity: parseFloat(element.quantity), 
              quantityOld: parseFloat(element.quantity),
              currency: element.currency,                        
              amount: parseFloat(element.amount),   
              discount: (element.itemDetail ? parseFloat(element.itemDetail.percentage_discount) : 0),                       
              unit_measurement: element.unit_measurement,
              price_list: element.price_list
            }

            this.crud.form.detail.push(arrItem)                  
            this.crud.form.editOrder = true
          });
          
          this.$emit('load-detail', this.crud.form.detail)
        }
      },      
      loadProductsCompound (object) {        
        if(object){
          this.crud.form.detail_register.productCompound = object
          this.crud.form.detail_register.productCompound_id = object.id     
                    
          this.crud.form.detail_register.forceRenderSelectProduct = object.id                  
        } else {                    
          this.crud.form.detail_register.productCompound = null
          this.crud.form.detail_register.productCompound_id = 0                            
          
          this.crud.form.detail_register.quantity = 0          
          this.crud.form.detail_register.amount = 0  
          this.crud.form.detail_register.discount = 0
          this.crud.form.detail_register.currency = null  
          this.crud.form.detail_register.unit_measurement = null
          this.crud.form.detail_register.unit_measurement_id = 0

          this.crud.form.detail_register.button.title = "Agregar Item"
          this.crud.form.detail_register.button.icon = "fa fa-plus"
          this.crud.form.detail_register.button.variant = "success"          
        }
      },    

      // MULTIPLE LIST PRICE
      selectMultipleListPrice(item) {        
        this.crud.form.detail_register.currency= item.list.currency 
        this.crud.form.detail_register.amount = item.price_simple   
        this.crud.form.detail_register.amount_initial = item.price_simple     

        if(item.list) {
          this.crud.form.detail_register.price_list_id = item.list.id
          this.crud.form.detail_register.price_list = item.list                  
        }      

        this.modal.multipleListPrice.active = false
      },
      cancelMultipleListPrice() {
        this.modal.multipleListPrice.active = false
      },          
            
      // ABM MAIN DETAILS
      addItem() {      
        // cantidad
        if (this.crud.form.detail_register.quantity.toString() == '' || parseFloat(this.crud.form.detail_register.quantity)<=0) {
          this.$awn.alert('La cantidad debe ser mayor a cero')          
          return false
        }
          
        // amount
        if (this.crud.form.detail_register.amount.toString() == '' || parseFloat(this.crud.form.detail_register.amount)<0) {                   
          this.$awn.alert('El precio no puede ser menor a cero')          
          return false          
        }

        // descuento
        if (this.crud.form.detail_register.discount.toString() == '' || parseFloat(this.crud.form.detail_register.discount)<0) {      
          this.$awn.alert('El descuento no puede ser menor a cero')          
          return false          
        }
        
        // producto compuesto
        if(!this.crud.form.detail_register.productCompound) {
          this.$awn.alert('No se cargó el producto')          
          return false
        }      
        
        // stock
        if(!this.validStockItem()) {
          this.$awn.alert('Stock Insuficiente') 
          return false
        }

        // duplicado
        if(!this.validDuplicatedItem()) {
          this.$awn.alert('Item Duplicado') 
          return false          
        }

        var arrItem = { 
          productCompound: this.crud.form.detail_register.productCompound,
          quantity: parseFloat(this.crud.form.detail_register.quantity),
          quantityOld: parseFloat(this.crud.form.detail_register.quantityOld),
          currency: this.crud.form.detail_register.currency,
          amount: parseFloat(this.crud.form.detail_register.amount),
          discount: parseFloat(this.crud.form.detail_register.discount),
          unit_measurement: this.crud.form.detail_register.unit_measurement,
          price_list: this.crud.form.detail_register.price_list
        }

        this.crud.form.detail.push(arrItem)
        this.$emit('load-detail', this.crud.form.detail)
        
        this.crud.form.detail_register.forceRenderSelectProduct = 0
        this.loadProductsCompound(null)                                    
        
        this.crud.form.detail_register.button.editing = false
      },
      editItem (item, index) {    
        this.crud.form.detail_register.editing = true
        this.crud.form.detail_register.quantity = item.quantity
        this.crud.form.detail_register.quantityOld = item.quantityOld
        this.crud.form.detail_register.discount = item.discount        
        this.crud.form.detail_register.price_list = item.price_list

        this.crud.form.detail_register.currency = item.price_list.currency 
        this.crud.form.detail_register.amount = item.amount   
        this.crud.form.detail_register.amount_initial = item.amount
        this.crud.form.detail_register.unit_measurement = item.unit_measurement
        
        if(this.editPrice) {
          this.editing.status = true
          this.editing.price = item.amount
          this.editing.unit_measurement = item.unit_measurement
        } 

        this.crud.form.detail_register.productCompound_id = item.productCompound.id
        this.crud.form.detail_register.forceRenderSelectProduct = item.productCompound.id    
        
        this.crud.form.detail_register.editing = false
        
        this.crud.form.detail_register.button.title = "Editar Item"
        this.crud.form.detail_register.button.icon = "fa fa-save"
        this.crud.form.detail_register.button.variant = "warning"
        this.crud.form.detail_register.button.editing = true
                     
        this.crud.form.detail.splice(index, 1)
        this.$emit('load-detail', this.crud.form.detail)
      },
      deleteItem (index) {
        this.crud.form.detail.splice(index, 1)
        this.$emit('load-detail', this.crud.form.detail)
      },

      // CALCULA DISCOUNT
      getRegDiscount(item) {        
        var totalDiscount = 0
        if(parseFloat(item.discount) > 0) {
          totalDiscount = parseFloat(item.quantity) * (( parseFloat(item.amount) * parseFloat(item.discount) ) / 100)
        }        
        return totalDiscount
      },
      getRegSubTotals(item) {
        var subtotal = parseFloat(item.quantity) * parseFloat(item.amount)
        return subtotal
      },       
      getRegTotals(item) {
        var total = 0
        if(parseFloat(item.discount) > 0) {
          var discount = (parseFloat(item.amount) * parseFloat(item.discount)) / 100
          var amount = parseFloat(item.amount) - parseFloat(discount)

          total = parseFloat(item.quantity) * parseFloat(amount)
        } else {
          total = parseFloat(item.quantity) * parseFloat(item.amount)
        }
        return total
      },      

      // VALIDA STOCK
      validStockItem() {        
        var status = true        
        if(this.validStock) {             
          if(this.crud.form.editOrder) {
            if(this.crud.form.detail_register.productCompound) {              
              
              let stockProduct = 0
              if(this.moduleDepositActive) {
                stockProduct = parseFloat(this.crud.form.detail_register.productCompound.stock)
              } else {
                stockProduct = parseFloat(this.crud.form.detail_register.productCompound.stock) + parseFloat(this.crud.form.detail_register.quantityOld)
              }

              if(this.crud.form.detail_register.quantity > stockProduct) {          
                this.crud.form.detail_register.quantity = stockProduct              
                var status = false
              }
            }
          } else {
            if(this.crud.form.detail_register.productCompound) {
              let stockProduct = parseFloat(this.crud.form.detail_register.productCompound.stock) 
              if(this.crud.form.detail_register.quantity >  stockProduct) {          
                this.crud.form.detail_register.quantity = stockProduct
                var status = false
              }
            }
          }
        }
        return status
      },  

      // VALID DUPLICATED
      validDuplicatedItem() {
        var item = this.crud.form.detail_register.productCompound
        var status = true
        if(this.crud.form.detail) {
          this.crud.form.detail.forEach(element => {                                                
            if(element.productCompound.id == item.id) {
              status = false
            }                         
          });
        }
        return status
      },                
    }
  }
</script>
<style>
  .crud-order-table-detail {
    overflow: auto;
    max-height: 350px;    
    min-height: 100px;    
  }
  .block-product-add {
    background: white;
    filter: brightness(0.4);
    border-radius: 15px;
    padding-top: 10px;
    pointer-events: none;
    opacity: 0.5;
  }
  .block-product-add-div {
    position: absolute;
    text-align: center;
    margin-top: 20px;    
  }
  .crud-new-orders-button-add {
    margin-top: 29px;
  }  
  .curd-detail-product-select-unit-measurement {
    font-size: 10px !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
  .curd-detail-product-select-unit-measurement button {
    font-size: 10px;
    padding-left: 3px;
    padding-right: 3px;
  }
  .crud-detail-product-prepend-amount .input-group-prepend .input-group-text {
    padding-left: 3px !important;
    padding-right: 3px !important;    
  }
  .crud-detail-product-append-discount .input-group-append .input-group-text {
    padding-left: 3px !important;
    padding-right: 3px !important;    
  }  
</style>