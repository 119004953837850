const parameters = {
	P1: 1, // general - Permite registrar a un usuario
	P2: 2, // customers - Visualiza el codigo en lugar del id en la grilla
	P3: 3, // suppliers - Visualiza el codigo en lugar del id en la grilla
	P4: 4, // sales flow - Visualiza datos complementarios en el potencial cliente
	P5: 5, // sales flow - Permite asignarle una valorización ($) al potencial cliente
	P6: 6, // sales flow - Exigir aprobación del potencial cliente para cambiar el estado a ganado o perdido
	P7: 7, // products - Utiliza lineas de productos
	P8: 8, // products - Habilitar la variedad por color
	P9: 9, // products - Habilitar la variedad por talle
	P10: 10, // products - Habilitar medidas en productos
	P11: 11, // contents - (*) Habilitar contenido de sliders (funcionalidad descontinuada). Usar contenido de Páginas
	P12: 12, // contents - (*) Habilitar contenido de popups.
	P13: 13, // contents - (*) Habilitar contenido de descargas.
	P14: 14, // contents - (*) Habilitar contenido de métodos de pago (funcionalidad descontinuada). Usar contenido de Páginas
	P15: 15, // contents - Habilitar contenido de páginas
	P16: 16, // sales flow - Genera contrato con los clientes ganados
	P17: 17, // products - Habilitar la variedad por color y talle
	P18: 18, // products - Habilitar modo marketplace
	P19: 19, // products - No mostrar el stock
	P20: 20, // products - Agregar multinivel en subcategorías (actualmente disponible en modo marketplace)
	P21: 21, // order - Permite asociar numeración interna a un pedido
	P22: 22, // sales flow - Permite asignar vendedor, staff y cliente a un Lead
	P23: 23, // sales flow - Permite crear servicio desde un Lead ganado
	P24: 24, // forms - Permite convertir una consulta en un Leads del CRM
	P25: 25, // staff - Habilitar Notas Internas  
  P26: 26, // remits - Remitos desde pedidos
  P27: 27, // remits - Remitos desde facturas
  P28: 28, // remits - Permite agregar items de diferentes pedidos o facturas
	P29: 29, // whatsapp-marketing - Envia mensajes sin delay
	P30: 30, // products - Habilita productos compuestos
  P31: 31, // products - Habilita unidades de medidas
  P32: 32, // customers - Valida que el email sea único para cada cliente  
	P33: 33, // general - Tarea programada: Monedas - Sincronizacion de Cotizaciones
	P34: 34, // general - Tarea programada: Sensores - Errores
	P35: 35, // general - Tarea programada: Whatsapp - Sincronizacion de Contactos
	P36: 36, // general - Tarea programada: Whatsapp - Envio de Mensajes
	P37: 37, // general - Tarea programada: Marketplace - Producto Disponible
	P38: 38, // general - Tarea programada: Servicios - Renovacion automatica
	P39: 39, // general - Tarea programada: Usuario - Seteo contraseña admin y superadmin
  P40: 40, // receipts - Permite crear recibos pendientes de aprobación
  P41: 41, // sales - Permite crear comprobantes de ventas pendientes de aprobación
  P42: 42, // general - Tarea Programada: Instancias - Checkeo estado de cada servidor
  P43: 43, // general - Tarea Programada: Instancias - Checkeo Backups Sistema
  P44: 44, // general - Tarea Programada: Pedidos - Busca los pedidos de productos con estado cerrado e intenta enviarlos al sistema de producción
  P45: 45, // contents - Habilitar portabilidad de plantillas
  P46: 46, // general - Tarea Programada: Instancias - Reinicio de todos los servidores
  P47: 47, // general - Tarea Programada: Instancias - Checkeo Auditoria Sistema  
	P48: 48, // general - Tarea Programada: Instancias - Reinicio de supervisor todos los servidores
}

export default parameters;
